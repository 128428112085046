import axios from "axios";
import singletonSession from "./IntanceSession";
import singletonMysqlApis from "./IntanceMysqlApis";


const initSession =  () => {

    const username = 'admin';
    const password = 'admin';
    const credentials = btoa(unescape(encodeURIComponent(username + ':' + password)));

    let config = {
        method: 'post',
        url:  process.env.REACT_APP_HOST_APIS+":"+process.env.REACT_APP_PORT_SESSION+"/api/login",
        headers: {
            'Authorization': 'Basic ' + credentials
        }
    };


    axios(config).then(function (response) {
        setTimeout(()=>{
            singletonSession.getInstance().post("/users/client").then(function (resp) {
                let  token = resp.data.AccesToken
                window.localStorage.setItem('session', token);
                singletonMysqlApis.setToken(token)
            }).catch(error =>  console.log(error))
        }, 100);
    }).catch(error => console.log(error));

}

export {
    initSession
}
