import axios from 'axios';

class InstanceSession{

  instance
  cookie
  username
  password = 'admin'
  credentials = btoa(this.username + this.password);


  getInstance(){
    return this.instance;
  }

  setCookie(cookie){
    this.cookie = cookie
  }

  setUser(user){
    const credentials = btoa(unescape(encodeURIComponent(user + ':' + this.password)));
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_HOST_APIS+":"+process.env.REACT_APP_PORT_SESSION+"/api",
      headers: {
        'Authorization': 'Basic ' + credentials,
        'Content-Type': 'application/json; charset=UTF-8'
      }
    })
  }

}

const singletonSession = new InstanceSession();



export default singletonSession;
